import React from "react"

import SEO from "../components/seo"
import ContainerWrapper from "../components/container"

const NotFoundPage = () => (
  <div className="mb-6">
    <SEO title="Forgot Password" keywords={[`mazrui`, `careers`]}/>
    <section className="section-password-reset mb-5 pb-5 mt-5 pt-5">
      <ContainerWrapper classNames="main" fluid>
        <ContainerWrapper className="login">
          <SEO title="404: Not found"/>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist...</p>
        </ContainerWrapper>
      </ContainerWrapper>
    </section>
  </div>
)

export default NotFoundPage
